import React from 'react';
import { Input } from 'antd';
import 'antd/dist/antd.css';
import './../../App.scss';
import './valri-form.scss';
import './numericInput.scss';
import Cookies from 'js-cookie';
import { formatNumber } from '../../utilities/formatNumber';

class NumericInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { soguid: undefined };
  }
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    if (onBlur) {
      onBlur();
    }
  };

  componentDidMount() {
    var guid = Cookies.get('soguid');

    this.setState({ soguid: guid });
  }

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className='numeric-input-title'>
        {value !== '-' ? formatNumber(value) : '-'}
      </span>
    ) : (
      'Enter GUID'
    );
    return (
      <>
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder='Enter GUID'
          maxLength={12}
        />
        {/* {process.env.REACT_APP_ENV === 'prod' ? (
            <label>Ex: 1333631, 1134320</label>
          ) : (
            <label>Ex: 410125236, 410125382</label>
          )} */}
      </>
    );
  }
}

export default NumericInput;
