import setSendBox from 'botframework-webchat-core/lib/actions/setSendBox';
import submitSendBox from 'botframework-webchat-core/lib/actions/submitSendBox';
import sendEvent from 'botframework-webchat-core/lib/actions/sendEvent';
import React, { Component, createRef, useRef } from 'react';
import { MentionsInput, Mention,  } from 'react-mentions';
import { Components } from 'botframework-webchat';
import SendButtonSvg from '../../assets/icons/sendButton.svg';
import MicrophoneSvg from '../../assets/icons/microphone.svg';
import classNames from 'classnames';
import { defaultStyle } from '../../styles/defaultAutoCompleteStyles';
import './autocomplete.scss';
import _, { random } from 'lodash';
import PropTypes from 'prop-types';
import AttachFileSVG from '../../assets/icons/attachFile.svg';
//import Emojis from './Emoji';
import sendFiles from 'botframework-webchat-core/lib/actions/sendFiles';
import axios from 'axios';
import Base64 from 'components/landing/Base64';
import CloseChatPopup from './CloseChatPopup';

const { MicrophoneButton, SendButton, SuggestedActions } = Components;

const chatOptions = {
  debounceTimeMs: 500,
  autocompleteStartLetterNumber: 3,
};
// const fileUploadInp=createRef('');

class ChatSendBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendBoxValue: '',
      inputCharLimit: 250,
      isSendButtonDisabled: true,
      arrowMoved: false,
      mentionsOpened: false,
      showEmojis: this.props.emojiattach,
      file: null,
      userid:"",
      directline:null,
      resetShow:false,
      disableSend:true
    };
    this.inputRef = React.createRef();
    this.mouseClick = false;
    this.fileUploadInp = React.createRef();
    
  }

  handleClose=()=>{
    this.setState({resetShow:true})
      }

      changeCloseModal=(action)=>{
        this.setState({resetShow:false})
        if(action==="yes"){
          this.props.store.dispatch(setSendBox("End Chat"));
          this.props.store.dispatch(submitSendBox());
          this.setState({disableSend:false})
          this.props.disableBot()
        }        
      }

  handleInputCharLimit = (value) => {
    const { isSendButtonDisabled } = this.state;
    if (value.length > 0 && isSendButtonDisabled)
      this.setState({ isSendButtonDisabled: !isSendButtonDisabled });
    if (value.length == 0 && !isSendButtonDisabled) {
      this.setState({ isSendButtonDisabled: !isSendButtonDisabled });
    }

    this.setState({ inputCharLimit: 250 - value.length, sendBoxValue: value });
  };

  onValueChange = (_, newValue) => {
    if (!this.state.arrowMoved && this.state.mentionsOpened && !this.state.sendBoxValue.startsWith(newValue) && !newValue.startsWith(this.state.sendBoxValue) && !this.mouseClick) {
      this.state.inputCharLimit = 250;
      this.props.store.dispatch(submitSendBox());
      this.state.sendBoxValue = '';
      this.setState({
        arrowMoved: false,
        mentionsOpened: false,
        isSendButtonDisabled: !this.state.isSendButtonDisabled
      })
      return;
    }
    this.mouseClick = false;
    
    this.setState({
      arrowMoved: false,
      mentionsOpened: false
    })

    let textBoxValue = newValue;

    if (textBoxValue.length > 250) textBoxValue = textBoxValue.slice(0, 250);

    if (textBoxValue.length <= 250) {
      this.props.store.dispatch(setSendBox(textBoxValue.trim()));
      this.handleInputCharLimit(textBoxValue);
    }
  };

  getLastWord(words) {
    const n = words.split(/[\s,]+/i);
    return n[n.length - 1];
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.state.inputCharLimit = 250;
    this.props.store.dispatch(submitSendBox());
    this.state.sendBoxValue = '';
    this.setState({ sendBoxValue: "" });
  };

  onSend = () => {
    this.setState({
      isSendButtonDisabled: !this.state.isSendButtonDisabled,
      sendBoxValue: '',
    });
    this.setState({ inputCharLimit: 250 });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSubmit(event);
      this.setState({ isSendButtonDisabled: !this.state.isSendButtonDisabled });
    }
  };

  // get mentions method
  getData = _.debounce((query, callback) => {
    this.setState({
      mentionsOpened: false
    })
    const lastWord = this.getLastWord(this.state.sendBoxValue).toLowerCase();
    // this.props.store.dispatch(sendEvent("get-mentions", lastWord)); - old method getting mentions. could be deleted
    /*
    // lastWord - is word to search in autocomplete. it's a current last word in message*/
    fetch('/valri/symptom/search?query=' + lastWord)
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          callback([]);
          throw new Error('Bad response from server');
        }
        return response.json();
      })
      .then((returnedResponse) => {
        console.log(returnedResponse);
        this.setState({
          mentionsOpened: returnedResponse.length > 0 ? true: false 
        })
        callback(returnedResponse);
      })
      .catch((error) => {
        callback([]);
        console.log(error);
      });
  }, chatOptions.debounceTimeMs);

  childdata = (emojisdata) => {
    var emojitext = this.state.sendBoxValue + emojisdata;
    if (emojitext) {
      emojitext.length > 0 ? this.setState({ isSendButtonDisabled: false, sendBoxValue: this.state.sendBoxValue + emojisdata, inputCharLimit: 250 - emojitext.length,}) : this.setState({ isSendButtonDisabled: true });
      this.props.store.dispatch(setSendBox(emojitext));
    }
  }

  handleImport=()=>{
    this.fileUploadInp.current.click();
    }; 

    //  onFileUpload = (event) => {
    //    this.setState({
    //      file:event.target.files[0]
    //    })
    // let selectedFile=event.target.files[0].name
    // // let updatedFile=sendFiles(event.target.files[0])
    // // let updatedFile2=setSendBox(event.target.files[0])
    // // console.log("from sendfiles",updatedFile);
    // // console.log("from sendBOX",updatedFile2);
    // // const blobsInContainer = event.target.files[0];
    // if (selectedFile) {
    //    this.setState({ isSendButtonDisabled: false, sendBoxValue:selectedFile}) }
    //    else{ this.setState({ isSendButtonDisabled: true });
    //    this.props.store.dispatch(setSendBox(selectedFile));
    //   //  this.props.store.dispatch(submitSendBox());
    //    }
    // // this.props.store.dispatch(setSendBox(selectedFile));
    // };

    onFileUpload = (event) => {
      this.setState({
        file:event.target.files[0]
      })

   let selectedFile=event.target.files[0].name
   let updatedFile=event.target.files[0]
  //  const url = `${this.props.directline.domain}/conversations/${this.props.directline.conversationId}/upload?userId=${this.props.userId}`;
//   const headers = {
//     'Authorization': 'Bearer '+ this.props.directline.token, 
//     'Content-Type': 'image/png',
// 'Content-Disposition':`name=file;filename=${event.target.files[0].name}`
  
//   }
const timest = new Date().toISOString(); //userIdOnStartConversation,
const name='window-load'
const formData = new FormData();
formData.append('file', event.target.files[0]);
// formData.append('activity', event.target.files[0]);
const url = `${this.props.directline.domain}/conversations/${this.props.directline.conversationId}/upload?userId=${this.props.userId}`;
// const body = {
//   name,
//    type:"event",
//    channelData: { 
//      clientActivityID: Date.now() + random().toString(36).substr(2),
//      clientTimestamp: timest,
//      payload: Base64.btoa(document.cookie)
//    },
//    channelId:"webchat",
//    from:{
//      id:this.props.userId,
//      name:this.props.userName,
//      role:"user"
//    },
//    text: "",
//    value:"memberservice",
//    locale:"en-US",
//    timestamp: timest,

// attachments:[{
//    contentType:"text/plain",
// name:event.target.files[0].name,
// content:event.target.files[0]
//  }]};
 
const headers = {
   'Authorization': 'Bearer '+ this.props.directline.token, 
   'content-type': 'multipart/form-data',
   
'Content-Disposition':`name=file;filename=${event.target.files[0].name}`
 
 }
 axios.post(url, formData , {
   headers
 }).then((response)=>{
  console.log('The response from api is:-',response)
  }).catch((err)=>{
  console.log('The error from api is:-',err);
  });

  console.log("the url is:",url);

   // const blobsInContainer = event.target.files[0];
   if (selectedFile) {
    selectedFile.length ? this.setState({ isSendButtonDisabled: false,inputCharLimit: 250 - selectedFile.length}) 
     : this.setState({ isSendButtonDisabled: true });
    //  this.props.store.dispatch(setSendBox(selectedFile));
    //  this.props.store.dispatch(submitSendBox());
   }
//    axios.post(url,event.target.files[0], {
//     headers
//   }).then((response)=>{
// console.log('The response from api is:-',response)
// }).catch((err)=>{
// console.log('The error from api is:-',err);
// });
   // this.props.store.dispatch(setSendBox(selectedFile));
   };
   componentDidUpdate(){
    //  if(this.props.directline && this.props.userId!==""){
    //    this.setState({
    //     //  directline:this.props.directline
        
    //    })
    //    this.setState({
    //     // userid:this.props.userId
    //   })
    // console.log("from directline:",this.props.directline)
    // console.log("from userID:",this.props.userId)
    //  }
   }

   componentDidMount(){

    document.onkeydown = (event) => {
      if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
        this.setState({
          arrowMoved: true
        })
      }
      
    }
    this.props.store.subscribe(() => {
      const { sendBoxValue } = this.props.store.getState();
      this.setState({
        sendBoxValue,
      });

    });

    /*code to change the default icon of the send button and microphone button*/
    const sendButtonClass = document.getElementsByClassName(
      'webchat__send-button'
    );
    const sendButton = document.getElementsByClassName('input-box-btn');
    if (sendButton.length > 0)
      sendButton[0].addEventListener('click', this.onSend);

    const microphonebuttonClass = document.getElementsByClassName(
      'webchat__microphone-button__button'
    );

    const sendButtonchild = sendButtonClass[0].childNodes;
    const microphoneChild = microphonebuttonClass[0].childNodes;
    const senButtonImg = document.createElement('img');
    const microphoneImg = document.createElement('img');

    // senButtonImg.src = SendButtonSvg;
    // microphoneImg.src = MicrophoneSvg;
    sendButtonchild[0].replaceWith(senButtonImg);
    microphoneChild[0].replaceWith(microphoneImg);
    /**End of the code to change the default icon of the send and microphone button */
    this.inputRef.current.onKeyPress = (e) => {
      // e.stopPropagation();
     // console.log('keypress');
    }
  }

  render() {
    return (
      <>
        <div onKeyPress={() => {

        }} className={this.state.disableSend ? "webchat__send-box chat-inputbox-container" : "webchat__send-box chat-inputbox-container-ptr-events"}
    >
          {this.state.resetShow&&
<CloseChatPopup show={true} changeShow={this.changeCloseModal} store={this.props.store}/>}
          <div className={'chat-inputbox-wrapper webchat__send-box__main'}>
            <div className='chat-microphone-container'>
              <MicrophoneButton
                className={classNames(
                  'webchat__send-box__button',
                  'webchat__send-box__microphone-button'
                )} 
              />
              {this.props.emojiattach ?
                  <img src={AttachFileSVG} onClick={()=>this.handleImport()} style={{ cursor: "pointer" }}  width="35" height="35" />
                      :null} 
                 
  <input type="file" ref={this.fileUploadInp} style={{display:"none"}} onChange={this.onFileUpload}/>
          
           {/* {this.props.emojiattach ? */}
                 {/* <div className="webchat__emojiicon"><Emojis autoemoji={this.childdata} ></Emojis></div> */}
                    {/* :null}     */}
            </div>
            <div  className='inputbox-container'>
              <div className='inputbox-wrapper'>
                <MentionsInput
                  
                  value={this.state.sendBoxValue}
                  onChange={this.onValueChange}
                  rows={1}
                  inputRef={this.inputRef}
                  className='webchat__send-box-text-box'
                  style={defaultStyle}
                  placeholder='Type your message'
                  allowSuggestionsAboveCursor={true}
                  maxLength={250}
                  onKeyPress={this.handleKeyPress}
                  contentEditable={
                    this.state.inputCharLimit <= 0 ? false : true
                  }
                  onFocus={() => {
                    //console.log('focus')
                  }}                  
                  suppressContentEditableWarning={true}
                >
                  <Mention
                    trigger={
                      new RegExp(
                        `(?:^|\\s)([a-z]{${chatOptions.autocompleteStartLetterNumber},}([^\\s]*))$`,
                        'i'
                      )
                    }                      
                    data={this.getData}
                    markup='__display__  '
                    renderSuggestion={(
                      suggestion,
                      search,
                      highlightedDisplay,
                      index,
                      focused,
                    ) => (
                      <div onMouseOver={() => {
                        console.log('onkeydown');
                      }} onClick={(e) => {
                        this.mouseClick = true;
                        // console.log('click here');
                      }} className={`user ${focused ? 'focused' : ''}`}>
                        {highlightedDisplay}
                      </div>
                    )}
                    appendSpaceOnAdd={true}
                  />
                </MentionsInput>
                <div
                  className={`input-box-btn ${
                    this.state.isSendButtonDisabled
                      ? 'input-box-btn-disabled'
                      : ''
                  }`}
                >
                  <SendButton className={'webchat__send-box__button'} />
                </div>
              </div>
              <div className='input-character-container'>
                <span className='input-character'>
                  {' '}
                  {this.state.inputCharLimit} out of 250 characters left
                </span>
              </div>
            </div>
            <div className='end-chat'>
            <button className='close-button' onClick={this.handleClose}>End Chat</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ChatSendBox.propTypes = {
  store: PropTypes.object,
};

export default ChatSendBox;