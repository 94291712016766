import WebChatContainter from 'components/chat/web-chat-container';
import React from 'react';
import kaiserSvg from '../../assets/images/logo.svg';
import KPMobileSVG from '../../assets/images/KPMobile.svg';
import './chatLandingWindow.scss';
import { Layout } from 'antd';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useLocation } from 'react-router-dom';

const ChatWindow = ({ requestType }) => {
  const { height, width } = useWindowDimensions();
  return (
    <div className='main-container'>
      {requestType === 'appointment' ? null : (
        <div className='logo-container'>
          <div className='logo-wrapper'>
            {width > 741 && (
              <img alt='KP Logo' className='kp-logo' src={kaiserSvg} />
            )}
            {width <= 741 && (
              <img alt='KP Mobile Logo' className='kp-logo' src={KPMobileSVG} />
            )}
          </div>
        </div>
      )}
      <WebChatContainter requestType={requestType} />
    </div>
  );
};

export default ChatWindow;
