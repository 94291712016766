function fontFamily(fonts) {
  return fonts.map((font) => `'${font}'`).join(', ');
}

const DEFAULT_ACCENT = '#0063B1';
const DEFAULT_SUBTLE = '#767676'; // With contrast 4.5:1 to white
const KP_BLUE = '#003B71';
const PADDING_REGULAR = 10;
const DEFAULT_OPTIONS = {
  // Color and paddings
  accent: DEFAULT_ACCENT,
  backgroundColor: '#F7F7F7',
  cardEmphasisBackgroundColor: '#F0F0F0',
  //paddingRegular: PADDING_REGULAR,
  //paddingWide: PADDING_REGULAR * 2,
  subtle: DEFAULT_SUBTLE,
  // Word break
  messageActivityWordBreak: 'break-word', // 'normal' || 'break-all' || 'break-word' || 'keep-all'

  // Fonts
  fontSizeSmall: '80%',
  monospaceFont: fontFamily(['Gotham']),
  primaryFont: fontFamily(['Gotham']),

  // Bubble
  // TODO: Should we make a bubbleFromBot*
  // bubbleBackground: '#003B71',
  bubbleBackground: '#FFFFFF',
  bubbleBorderColor: '#e9ebee',
  // bubbleBorderRadius: '21px 21px 21px 0px',
  bubbleBorderRadius: '16px 16px 16px 0px',
  bubbleBorderStyle: 'solid',
  bubbleBorderWidth: 1,
  supportSpeechRecognition: false,
  // bubbleTextColor: 'white',
  bubbleTextColor: KP_BLUE,
  bubbleNubOffset: 'bottom', // Either a positive/negative number, or "bottom"
  bubbleNubSize: 0, // Or a number. 0 means a sharp corner.
  // bubbleFromUserBackground: '#91cdf1',
  bubbleFromUserBackground: KP_BLUE,
  // bubbleFromUserBorderColor: '#91cdf1',
  bubbleFromUserBorderColor: KP_BLUE,
  bubbleFromUserBorderRadius: 21,
  bubbleFromUserBorderStyle: 'solid',
  bubbleFromUserBorderWidth: 1,
  bubbleFromUserNubOffset: 'bottom', // Either a positive/negative number, or "bottom"
  bubbleFromUserNubSize: 0, // Or a number. 0 means a sharp corner.
  bubbleFromUserTextColor: '#FFFFFF',
  bubbleImageHeight: 240,
  // bubbleImageHeight: 290,
  bubbleMaxWidth: 530, // screen width = 600px
  bubbleMinHeight: 40,
  //botAvatarImage: 'https://docs.microsoft.com/en-us/azure/bot-service/v4sdk/media/logo_bot.svg?view=azure-bot-service-4.0',
  // botAvatarInitials: 'BF',
  //userAvatarImage: 'https://github.com/compulim.png?size=64',
  // userAvatarInitials: 'OX',
  //  bubbleMinWidth: 250, // min screen width = 300px, Edge requires 372px (https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/13621468/)

  // Markdown
  markdownRespectCRLF: true,

  // Rich Cards
  richCardWrapTitle: false, // Applies to subtitles as well

  // Root
  // rootHeight: '100%',
  rootWidth: '100%',
  rootZIndex: 0, // "z-index" for the root container of Web Chat. This will form a new stacking context so "z-index" used in children won't pollute.

  // Scroll to end button
  hideScrollToEndButton: true,
  // autoScrollSnapOnActivity: true,
  autoScrollSnapOnPage: true,

  // Send box
  hideUploadButton: true,

  // Visually show spoken text
  showSpokenText: false,

  // Timestamp
  // groupTimestamp: false,
  groupTimestamp: true,
  sendTimeout: 20000,
  sendTimeoutForAttachments: 120000,
  timestampColor: undefined, // defaults to subtle
  timestampFormat: 'absolute', //'relative'
  // timestampFormat: 'relative',
  showDisplayName: false,
  // Video
  //videoHeight: 270, // based on bubbleMaxWidth: 480 / 16 * 9 = 270
};

export default DEFAULT_OPTIONS;
