import './customActionButtons.scss';
import React, { useState, useMemo } from 'react';

function CustomActionButtons({ buttons, sendText }) {
  const rows = useMemo(
    () =>
      buttons.map((b) => {
        switch (b.Type) {
          case 'Link':
            return (
              <a className='custom-action-btn' target='_blank' href={b.Value}>
                {b.DisplayText}
              </a>
            );
          case 'Skill':
            return (
              <button
                className='custom-action-btn'
                onClick={() => sendText(b.Value)}
              >
                {b.DisplayText}
              </button>
            );
          default:
            throw new Error('Type unsupported');
        }
      }),
    [buttons]
  );

  const [seeMore, setSeeMore] = useState(false);
  const showMore = () => {
    setSeeMore(!seeMore);
  };

  const lowerRows = useMemo(() => rows.slice(3, rows.length), [buttons]);
  const upperRows = useMemo(() => rows.slice(0, 3), [buttons]);

  return (
    <div className='custom-action-container'>
      <div className='custom-action-container'>{upperRows}</div>
      <div className='custom-action-container'>{seeMore ? lowerRows : ''}</div>

      {rows.length > 3?<a className='custom-action-container-action' onClick={showMore}>
        <span style={{ fontSize: '20px' }}>{seeMore ? '-' : '+'}</span>
        <span style={{ paddingLeft: '1px' }}>
          List {seeMore ? 'less' : 'more'}
        </span>
      </a>:''}
    </div>
  );
}

export default CustomActionButtons;
