export const defaultStyle = {
  control: {
    border: 0,
  },

  '&multiLine': {
    highlighter: {
      padding: 0,
      border: 0,
      width: '30vw',
    },
    input: {
      resize: 'none',
      padding: 9,
      border: 0,
    },
  },

  suggestions: {
    marginTop: '30px',
    marginLeft: '10px',
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
