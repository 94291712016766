import React from 'react'
import {Spin, Row, Col} from 'antd'


const LoadingMessage = () => {

    return (
        <Row  justify='center'>
            <Col>
                <Spin size='large'/>
            </Col>
        </Row>
    )
}

export default LoadingMessage