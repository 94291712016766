import React, { Component, useState } from 'react';
import './DropdownAutocomplete.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const {
  hooks: { useSendPostBack },
} = window.WebChat;

function DropdownAutocomplete(props) {
  const store = props.store;
  let data = JSON.parse(props.res);
  const uniqueId = props.uniqId;
  let resp = '';
  const sendPostBack = useSendPostBack();
  let response = {};
  let CompactSelectVal = {};
  let dropdownresponse = { dropdownresponse: true };
  const [theArray, setTheArray] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [disable, setDisabled] = useState(false);
  const [text, setText] = React.useState('');
  const [option, setOption] = useState([{}]);
  const items = [];
  // console.log(selectedTeam);

  data.items.forEach(function (item, index) {
    items.push(data.items[index]);
    //options.push(getRadioValues(index));
  });

  const onSubmit = (event) => {
    setDisabled(true);
    // let checkDisable=document.getElementById("nba teams");
    // var inputVal = "";
    // if (checkDisable) {
    //   inputVal = checkDisable;
    //   inputVal.disabled=true;
    // }
    if (selectedTeam != null) {
      let x = selectedTeam.value;
      event.preventDefault();
      // setTheArray(x);
      resp = x;
      CompactSelectVal['CompactSelectVal'] = resp;
      response = { ...CompactSelectVal, ...dropdownresponse };
      console.log(JSON.stringify(response));
      sendPostBack(JSON.stringify(response));
    }
    // else if(selectedTeam!=null && text!==null){
    //     let x= selectedTeam.value;
    //       resp = x;
    //           CompactSelectVal['CompactSelectVal'] = resp;
    //           response = { ...CompactSelectVal, ...multiCheckResponse };
    //           console.log(JSON.stringify(response));
    //           sendPostBack(JSON.stringify(response));
    // }
    else {
      console.log(text);
      resp = text;
      CompactSelectVal['CompactSelectVal'] = resp;
      response = { ...CompactSelectVal, ...dropdownresponse };
      console.log(JSON.stringify(response));
      sendPostBack(JSON.stringify(response));
    }
  }

  const handleChange = (event, newTeam) => {
    console.log(event.target.value);
    //  const target = event.target;
    //  setText(prev => ({ ...prev, [target.id]: target.value }));
    newTeam = event.target.value;
    setText(newTeam);
  }

  return (
    <div>
      {/* {data.items.map(item => { */}
      <Autocomplete
         freeSolo
        id="nba teams"
        disabled={disable}
        //value={value}
        options={items}
        renderInput={params => (
          <TextField {...params} label="" variant="outlined" onChange={handleChange} value={text} fullWidth />
        )}
        getOptionLabel={option => option.choice}
        style={{ width: 500 }}
        value={selectedTeam}

        onChange={(_event, newTeam) => {
          setSelectedTeam(newTeam);
        }}
      />
      {/* })} */}
      <div>
        <br></br>
        <br></br>
        <br></br>
        <button className='st-dropdown-submit' disabled={disable} onClick={onSubmit}>Submit</button>
      </div>

    </div>
  )
}

export default DropdownAutocomplete;