import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import LoadingMessage from './components/common/loading-message';
import './App.scss';
import './chat.scss';
import Home from 'components/landing/home';

function App() {
  const content = (
    <Router>
      <Switch>
        <Route exact path='/valri'>
          <Home requestType={'valri'} />
        </Route>
        <Route exact path='/memberservice'>
          <Home requestType={'memberservice'} />
        </Route>
        <Route exact path='/appointment'>
          <Home requestType={'appointment'} />
        </Route>
        <Route exact path='/valri-oauth'>
          <Home requestType={'valri-oauth'} /> 
        </Route>
      </Switch>
    </Router>
  );

 

  return (
    <div className='App'>
      <Suspense fallback={<LoadingMessage />}>{content}</Suspense>
    </div>
  );
}

export default App;
