import React from 'react';
import './ButtonGroupComp.scss';
//import Button from "@material-ui/core/Button";
//import Tooltip from "@material-ui/core/Tooltip";
import { Tooltip } from 'antd';

function ButtonGroupComp(props) {
  const [response, setResponseValue] = React.useState('');
  const store = props.store;
  let options = props.options;
  const [active, setActive] = React.useState(options[options.length - 1]);
  let questionId = props.questionId;
  let uniqueId = props.uniqueId;
  let resp = {};
 

  function toggleBtnStyle(eleId) {
    let eleClassList = document.getElementById(eleId).nextSibling.classList;
    toggleBtnClassChecked(eleClassList);
    let ids = eleId.split('-', 3);
    let uniqueId = eleId.replace(
      ids[0] + '-' + ids[1] + '-' + ids[2] + '-',
      ''
    );
    for (let i = 0; i < 4; i++) {
      if (i != ids[1]) {
        var ele = document.getElementById(
          ids[0] + '-' + i + '-' + ids[2] + '-' + uniqueId
        );
        if (ele)
          toggleBtnClassUnChecked(
            ele.nextSibling.classList
          );
      }
    }
  }

  function toggleBtnClassChecked(eleClassList) {
    eleClassList.replace('st-btn-primary', 'st-btn-primary-checked');
  }

  function toggleBtnClassUnChecked(eleClassList) {
    eleClassList.replace('st-btn-primary-checked', 'st-btn-primary');
  }

  
  return (
    <div className='st-button-group'>
      <div role='group'>
     
        {options.map((radio, idx) => (
      //  <Tooltip placement="bottom" title={radio} >
        
          <span key={idx}>
            <input
              className='st-btn-check'
              name='radio'
              type='radio'
              autoComplete='off'
              id={`radio-${idx}-${questionId}-${uniqueId}`}
              value={radio}
              onChange={(e) => {
                resp[questionId] = e.currentTarget.value;
                //setResponseValue(e.currentTarget.value);
                props.OnButtonGroupSelection(resp);
              }}
              onClick={(e) => {
                setActive(radio);
                toggleBtnStyle(e.target.id);
              }}
            />
             
            <label
            
              htmlFor={`radio-${idx}-${questionId}-${uniqueId}`}
              role='button'
              className={
                idx === 3 
                  ? 'st-btn st-btn-primary-checked st-btn-font st-mr-5'
                  : options.length === 2 || options.length === 3 ?'st-btn-three-style st-btn-primary st-mr-5 ':'st-btn st-btn-primary st-btn-font st-mr-5'
                  }
            >
              {radio}
            </label>
            
            
          </span>
         
          // </Tooltip>
          
          
        ))}
        
      </div>
    </div>
  );
}
export default ButtonGroupComp;
