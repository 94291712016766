import React, { Component, useState } from 'react';
import './MultiSelectCheckbox.scss';
const {
  hooks: { useSendPostBack },
} = window.WebChat;

function MultiSelectCheckbox(props) {
  const store = props.store;
  let data = JSON.parse(props.res);
  const uniqueId = props.uniqId;
  let result = [];
  const [theArray, setTheArray] = useState([]);
  const[checkboxvalue,setCheckboxValue]=useState([]);
  let resp = '';
  const sendPostBack = useSendPostBack();
  let CompactSelectVal = {};
  let response = {};
  let multiCheckResponse = { multicheckresponse: true };
  let options = [];
  const[disable,setDisabled]=useState(false);
  const[valid,setValid]=useState(false);

  data.items.forEach(function (item, index) {
    options.push(data.items[index]);
    //options.push(getRadioValues(index));
  });

  const handleChange = (event) => {
    //var isChecked = event.target.checked;
    let item = event.target.value.split('&|!');

    let value= item[0];
    let flag=item[1];
    let itemSelected = {};
    let tempArray=[];
    
    if(!event.target.checked){
      tempArray=[...theArray];
      tempArray.forEach((arr,arri)=>{
        if(arr===value){
          tempArray.splice(arri,1);
        }
      });
     setTheArray(tempArray);
    }
    else{
    
    for (let i = 0; i <= options.length - 1; i++) {
     
      if(options[i].value === value){
              itemSelected=options[i];
              
              break;
      }
            }

            if(itemSelected.flag){
              tempArray.push(value);
              setTheArray(tempArray);
              let tempObj={item:value,flag:flag}
      let checkBoxArray=[];
              checkBoxArray.push(tempObj)
              setCheckboxValue(checkBoxArray);
              for (let i = 0; i <= options.length - 1; i++) {
                if(options[i].value !== value){
              document.getElementById(
                'checkbox-' + i + '-' + uniqueId
              ).checked = false;
              setValid(false);
              // theArray.splice(i,1);
                }
              }
            }

            // else if(flag==='false'){
            //   for (let i = 0; i <= options.length - 1; i++) {
            //     if(options[i].value !== value){
            //   document.getElementById(
            //     'checkbox-' + i + '-' + uniqueId
            //   ).checked = false;
            //   // theArray.splice(i,1);
            //     }
            //   }
            // }
            
            else{

              if(event.target.checked){
                
              if(checkboxvalue.length>=1){
                checkboxvalue.forEach((x,xi)=>{
                  if(x.flag === 'true'){
                    let val=x.item;
                    for (let i = 0; i <= options.length - 1; i++) {
                      if(options[i].value === val){
                 document.getElementById(
                'checkbox-' + i + '-' + uniqueId
              ).checked = false;
              theArray.splice(xi,1);
              checkboxvalue.splice(xi,1);
              setValid(false);
                 }
                }
              
                  }
                })
              }
              setTheArray([...theArray,value]);
             setCheckboxValue([...checkboxvalue,{item:value,flag:flag}]);
              
              
              }
              
            }
    }
            
//     if (event.target.value == '25494' || event.target.value == '22974' || event.target.value=='29018' ) {
//       let tempArray=[];
// tempArray.push(event.target.value);
//       console.log('The list of options',options);
//       for (let i = 0; i <= options.length - 1; i++) {
// if(options[i].value!== event.target.value){
//         document.getElementById(
//           'checkbox-' + i + '-' + uniqueId
//         ).checked = false;
// }
//       }
//       setTheArray(tempArray);
//     }
//     // } else if (event.target.checked) {
//     //   setTheArray([...theArray, item]);
//     // }
//     else{
//       if(theArray[0]=='25494'||theArray[0]=='22974' ||theArray[0]=='29018'){
//  setTheArray([]);
// for (let i = 0; i <= options.length - 1; i++) {
//   if(options[i].value == '25494' || options[i].value == '22974' || options[i].value=='29018'){
//           document.getElementById(
//             'checkbox-' + i + '-' + uniqueId
//           ).checked = false;
//   }
  
//         }
//         setTheArray([event.target.value]);
//       }
//     }
    // } else {
    //   let arr = theArray.filter((i) => i !== item);
    //   setTheArray(arr);
    // }
    
  };

  const handleSubmit = (event) => {
    console.log(CompactSelectVal);
    event.preventDefault();
    setDisabled(true);

  };

  return (
    <div>
      <form>
        {
          <div>
            <span className='down-heading-style'>Please select all that apply:</span>
            {data.items.map((item, idx) => (
              <p key={idx}>
                <input
                  className='st-checkbox'
                  name='checkbox'
                  type='checkbox'
                  autoComplete='off'
                  id={`checkbox-${idx}-${uniqueId}`}
                  value={`${item.value}&|!${item.flag}`}
                  onChange={handleChange}
                  onClick={(e) => {
                    if (e.currentTarget.checked) {
                      setDisabled(false);
                      setValid(false);
                      document
                        .getElementById(e.target.id)
                        .nextSibling.classList.replace(
                          'st-checkbox-btn-primary',
                          'st-checkbox-btn-primary-checked'
                        );
                    } else {
                      
                      document
                        .getElementById(e.target.id)
                        .nextSibling.classList.replace(
                          'st-checkbox-btn-primary-checked',
                          'st-checkbox-btn-primary'
                        );
                    }
                  }}
                />
                <label
                  htmlFor={`checkbox-${idx}-${uniqueId}`}
                  className='st-checkboxlabel'
                >
                  {item.choice}
                </label>
              </p>
            ))}
          </div>
        }
       <div>
        {valid && <span className='error'> Please select atleast one checkbox </span>}
        </div>
       <br></br>
        <div className='st-submit-div-checkbox'>
          <button
            type='button'
            className='st-checkbox-submit'
            disabled={disable}
            onSubmit={handleSubmit}
            onClick={(event) => {
              if(theArray.length<=0){
                setDisabled(true);
                setValid(true);
              }
              else{
              event.preventDefault();
              resp = theArray.join(',');
              CompactSelectVal['CompactSelectVal'] = resp;
              response = { ...CompactSelectVal, ...multiCheckResponse };
              console.log(JSON.stringify(response));
              sendPostBack(JSON.stringify(response));
              setDisabled(true);
              setValid(false);
              
              for (let i = 0; i <= options.length; i++) {
                let checkDisable=document.getElementById(
                  'checkbox-' + i + '-' + uniqueId);
                var inputVal = "";
                if (checkDisable) {
                  inputVal = checkDisable;
                  inputVal.disabled=true;
                }
                  
                }

                
              }
              
              // document.getElementById('webchat__bubble__content').disabled=true;
            }}
          >
            {' '}
            Submit
          </button>
          
        </div>
      </form>
    </div>
  );
}
export default MultiSelectCheckbox;
