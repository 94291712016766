import axios from 'axios';
import random from 'math-random';

export const sendCustomEvent = ({directLine, name, userId, userName, requestType, eventPayload }) => {
  const timest = new Date().toISOString(); //userIdOnStartConversation
  const body = {
    name,
    type:"event",
    channelData: { 
      clientActivityID: Date.now() + random().toString(36).substr(2),
      clientTimestamp: timest,
      payload: eventPayload
    },
    channelId:"webchat",
    from:{
      id:userId,
      name:userName,
      role:"user"
    },
    text: "",
    value:requestType,
    locale:"en-US",
    timestamp: timest
    
  };
  const url = `${directLine.domain}/conversations/${directLine.conversationId}/activities`;
  const headers = {
    'Authorization': 'Bearer '+ directLine.token, 
    'Content-Type': 'application/json'
  }
  axios.post(url, body, {
    headers
  });
}