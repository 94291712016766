import React, { useEffect, useState } from 'react';
// @ts-ignore
import axios from 'axios';
import './web-chat-container.scss';
import WebChat from './web-chat';
import 'antd/dist/antd.css';
import './../../App.scss';
import { Layout } from 'antd';
import Cookies from 'js-cookie';
import ValriForm from './valri-form';
import { useLocation, useHistory } from 'react-router-dom';
import Base64 from 'components/landing/Base64';
import request from 'request';
var crypto = require('crypto');
const { Content } = Layout;
var uuid = require('uuid');

// @ts-ignore
const WebChatContainter = ({ requestType }) => {
  const query = new URLSearchParams(useLocation().search);
  const conversationIdInit = query.get('conversationId');
  const tokenInit = query.get('token');
  const inputChatInfo = query.get('chatInfo');

  const [conversationId, setConversationId] = useState(conversationIdInit);
  const [token, setToken] = useState(tokenInit);
  // @ts-ignore
  const [searchValue, setSearchValue] = useState('');
  const [mrnData, setMrnData] = useState('');
  const [soguid, setSoguid] = useState();
  const [showEndChat, setShowEndChat] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [mailIntent, setMailIntent] = useState();
  const [smarttriageSkillPayload, setSmartTriageSkillPayload] = useState('');
  // @ts-ignore
  const locationFinder = new URLSearchParams(useLocation().search);
  if(requestType === 'memberservice' && locationFinder.get('envlbl')!=null)
  {
    document.cookie = "envlbl="+locationFinder.get('envlbl');
    window.history.pushState({}, document.title, "/" + "memberservice");
  }
 // 
  const url =
    'https://directline.botframework.com/v3/directline/tokens/generate';
  const history = useHistory();

  useEffect(() => {    
    var guid = Cookies.get('soguid');
    var locale = Cookies.get('locale');
    var chatInput = ' , , , ,' + guid + ',' + locale + ', , , , ,';

    
    if (locale == 'undefined' || locale == '' || locale == undefined)
      locale = 'en-US';
    if(requestType === 'memberservice'){
      if( inputChatInfo){
        var ci = JSON.parse(Base64.atob(inputChatInfo));      
        guid = ci['soguid'];
      }
      chatInput = ' , , , ,' + guid + ',' + locale + ', , , ,memberservice,';
      setShowInput(!guid || guid == 'undefined');
    }else if (requestType === 'valri-oauth' && inputChatInfo){
      var ci = JSON.parse(Base64.atob(inputChatInfo));
      guid = ci['soguid'];
      if (ci['intent']){
        setMailIntent(ci['intent']);      
        chatInput = ' , , , ,' + guid + ',' + locale + ', , , , ,sendmail,';    
      }else{
        chatInput = ' , , , ,' + guid + ',' + locale + ', , , , , ,,';
      }
    }else if (requestType === 'appointment' && inputChatInfo){
      var ci = JSON.parse(Base64.atob(inputChatInfo));
      guid = ci['soguid'];
      chatInput = ' , , , ,' + guid + ',' + locale + ', , , ,smarttriageskill';
      var smartTriageSkillPayload = ci['intent'] + ',' + ci['riskIndicator'] + ',' 
                + ci['exitChatURL'] + ','               
                + ci['scriptName'] + ',' 
                + ci['soguid'] + ',' + ci['locale'] + ',' + ci['reason'] + ',' + ci['utterance'] + ',' 
                + ci['majorSymptom'] + ',smarttriageskill,'
                + ci['comments'];     
     var base_64 = Base64.btoa(smartTriageSkillPayload);
      setSmartTriageSkillPayload(base_64); 
    }else if (requestType === 'appointment'){
      setShowInput(true);
    }else if (token && conversationId) {
      setShowInput(false);
      setShowEndChat(!guid || guid == 'undefined');
    } else {
      setShowInput(!guid || guid == 'undefined');
    }
    
    // @ts-ignore
    setSoguid(guid);
    ///valri-oauth
    if (!showInput) {
      var output = Base64.btoa(chatInput);
      setMrnData(output);
      if (!token) {
        updateToken(output);
      }
    }
  }, []);


  const refreshToken = (chatInfo) => {
    console.log('hash length : ' + chatInfo.length);
    const params = {
      user: {
        id: 'dl_' + uuid.v4(),
        name: chatInfo,
        channelData: chatInfo
      }      
    };
    fetch('/chat/token?requestType=' + requestType, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error('Bad response from server');
        }
        return response.json();
      })
      .then((returnedResponse) => {
        setToken(returnedResponse.token);
      })
      .catch((error) => {
        setToken(null);
        console.log(error);
      });
  };

  const updateToken = (chatInfo) => {
    refreshToken(chatInfo);
  };

  const onEndChat = () => {
    setShowInput(true);
    setShowEndChat(false);
    setToken(null);
    setConversationId(null);
    setMrnData(null);
    history.push({ search: '' });
  };

  const onSubmit = (val, mrn) => {
    setSearchValue(val);
    var locale = Cookies.get('locale');
    if (locale == 'undefined' || locale == '' || locale == undefined)
      locale = 'en-US';
    var finalChatInfo =
      requestType === 'appointment'
        ? ' , , , ,' + mrn + ',' + locale + ', , , ,smarttriageskill'
        : ' , , , ,' + mrn + ',' + locale + ', , , ,';
    var output = Base64.btoa(finalChatInfo);
    setMrnData(output);
    updateToken(output);
    setShowInput(false);
    setShowEndChat(true);
    console.log('Token updated successfully for new conversation');
  };

  const showInputFields = (inputshow) => {
    if (inputshow) {
      setTimeout(() => {
        onEndChat();
      }, 5000);
    }
  };

  return (
    <>
      <div className={requestType == 'appointment' ?'st-chat-container' : 'chat-container'}>
        <div className='chat-wrapper'>
          <div className='main-chat'>
            <Layout className='container'>
              {showInput && !conversationId ? (
                <ValriForm onSubmit={onSubmit} requestType={requestType}/>
              ) : null}
              {token && !showInput ? (
                <Content>
                  <>
                    <WebChat
                      conversationId={conversationId}
                      token={token}
                      updateToken={updateToken}
                      mrnData={mrnData}
                      requestType={requestType}
                      soguid={soguid}
                      // @ts-ignore
                      showInputFields={showInputFields}
                      showEndChat={showEndChat}
                      endChatOnClick={onEndChat}
                      mailIntent ={mailIntent}
                      smartTriageSkillPayload = {smarttriageSkillPayload}
                    />
                  </>
                </Content>
              ) : null}
            </Layout>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebChatContainter;
