import React from 'react';
import Cookies from 'js-cookie';
import ChatWindow from 'components/chatLandingWindow/chatLandingWindow';

const Home = ({ requestType }) => {
  React.useEffect(() => {}, []);

  return <ChatWindow requestType={requestType} />;
};

export default Home;
