// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import React,{Component, useState,useEffect } from 'react';
import { Modal, Button } from 'antd';
//import 'bootstrap/dist/css/bootstrap.css';
import sendEvent from 'botframework-webchat-core/lib/actions/sendEvent';
// import BootstrapTable from 'react-bootstrap-table-next';
// import "../src/App.css";
//import '../../style/queue_role-style.css';
import axios from 'axios';

function CloseChatPopup(props){
const [show, setShow] = useState(false);
// const[popData,setPopData]=useState([]);
const handleClose = () => {
setShow(false);
props.changeShow("no");
}
useEffect(()=>{
console.log('Prop passed is:-',props.show);
setShow(props.show);
// parseFn(props.rowData.category);
},[]);

const handleYes=()=>{
    //props.store.dispatch(sendEvent("Reset"));
     setShow(false);
    props.changeShow("yes");
   // props.changeShow("no");
}


    return (
        <Modal 
        title="Are you sure you want to close the chat window?"
        centered 
        visible={show} 
        onOk={handleYes} 
        onCancel={handleClose}
        okText="Yes,end chat"
          cancelText="No,continue chat">
        <p>This will end your current chat session</p>
        
      </Modal>
    );
    }
    
export default CloseChatPopup;
