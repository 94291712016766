import React from 'react';
import 'antd/dist/antd.css';
import './../../App.scss';
import './valri-form.scss';
import { Form, Select } from 'antd';
import _, { findLastIndex } from 'lodash';
import Cookies from 'js-cookie';
import NumericInput from './numericInput';
const { Option } = Select;

class ValriForm extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      loading: false,
      mrninput: '',
      options: [],
      open: false,
      searchValue: '',
      getHelpdisabled: true,
      prevSearchVal: '',
      selectedValue: '',
      soguid: undefined,
      requestType: props.requestType
    };
  }
  componentDidMount() {
    this._isMounted = true;
    var guid = Cookies.get('soguid');
    this.setState({ soguid: guid });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = (value) => {
    this.setState({ mrninput: value, getHelpdisabled: false });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.searchValue.indexOf(this.state.selectedValue) !== -1) {
      this.props.onSubmit(this.state.searchValue, this.state.mrninput);
    } else if (this.state.searchValue == '' && this.state.selectedValue != '') {
      this.props.onSubmit(this.state.selectedValue, this.state.mrninput);
    } else {
      var userInput = this.state.searchValue;
      var symptom = this.state.selectedValue;
      if (symptom != this.state.selectedValue) {
        var newString = userInput.split(' ').slice(0, -1).join(' ');
        symptom = newString + ' ' + this.state.selectedValue;
      }
      this.props.onSubmit(symptom, this.state.mrninput);
    }
  };

  changeVal(value) {
    console.log(value);
    if (value && value != '') {
      if (value !== this.state.selectedValue)
        this.setState({ searchValue: value });
      (async () => {
        this.setState({ options: [] });
        if (value.length >= 3) {
          this.setState({ getHelpdisabled: false });
          fetch('/valri/symptom/search?query=' + this.state.searchValue)
            .then((response) => {
              if (response.status >= 400 && response.status < 600) {
                this.setState({ options: [] });
                throw new Error('Bad response from server');
              }
              return response.json();
            })
            .then((returnedResponse) => {
              if (Array.isArray(returnedResponse) && this._isMounted) {
                this.setState({ loading: false });
                this.setState({ options: returnedResponse });
              }
            })
            .catch((error) => {
              this.setState({ options: [] });
              console.log(error);
            });
        } else {
          this.setState({ options: [] });
        }
      })();
    }
  }

  render() {
    return (
      <>
        <div className='valri-form-container'>
          <div className='valri-form-wrapper'>
            {
              //process.env.REACT_APP_ENV === 'prod' &&
              this.state.soguid === undefined ||
              this.state.soguid === 'undefined' ? (
                <div>
                  <p> Please Sign-in to use the Smart tool </p>
                  <a
                    target='_blank'
                    href= { this.state.requestType == 'memberservice' ? 'https://hreg1.kaiserpermanente.org/southern-california/sign-on#/signon' : 'https://healthy.kaiserpermanente.org/southern-california/sign-on#/signon'}
                  >
                    {' '}
                    Click Here to Sign-in
                  </a>
                </div>
              ) : (
                <>
                  <Form.Item label='GUID' className='valri-form-item'>
                    <NumericInput
                      className='valri-form-numeric-input'
                      value={this.state.mrninput}
                      onChange={this.onChange}
                    />
                  </Form.Item>
                  <Form.Item label=''>
                    <div className='valri-form-btn-container'>
                      <div className='valri-form-btn-wrapper'>
                        <button
                          disabled={this.state.getHelpdisabled}
                          className='search-button'
                          onClick={this.onSubmit}
                        >
                          Get Help
                        </button>
                      </div>
                    </div>
                  </Form.Item>
                </>
              )
            }
          </div>
        </div>
      </>
    );
  }
}

export default ValriForm;
