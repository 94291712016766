import React, { useEffect, useState } from 'react';
import './MultiSelect.scss';
import ButtonGroupComp from './ButtonGroupComp';
const {
  hooks: { useSendPostBack },
} = window.WebChat;

function MultiSelect(props) {
  const store = props.store;
  // const reload=props.reload;
  const uniqueId = props.uniqId;
  //this.setDefault = this.setDefault.bind(this);
  let prevRes = {};
  let data = JSON.parse(props.res);
  const [skip, setToggle] = React.useState(false);
 const [valid, setValid] = useState(false);
 //let valid=false;
  const [defaultValue, setDefaultValue] = React.useState({});
  const[ind, setInd]=React.useState();
  const sendPostBack = useSendPostBack();
  store.istoggleon = true;
  // let reloadParent;
  let response = {};
  let drugs = [];
  let options = [];

  let triageCheck = { smarttriagereact: true };
  const[disable,setDisabled]=useState(false);
  
  // function setCustDefault(){
  //   const [active, setActive] = useState(options[options.length-1]);
  // }

  data.Survey.questions.forEach(function (item, index) {
    drugs[data.Survey.questions[index].id] = data.Survey.questions[index].question;
    ////options.push(getRadioValues(index));
    options[data.Survey.questions[index].id] = getRadioValues(index);
    //setInd(index);
  });
  const [active, setActive] = React.useState('');
  const [oldSelections, setOldSelections] = React.useState({});
  function getRadioValues(questionIndex) {
    let radioValues = [];
    data.Survey.questions[questionIndex].items.forEach(function (item, index) {
      radioValues.push(data.Survey.questions[questionIndex].items[index].value);
    });
    return radioValues;
  }

  // useEffect(() => {
  //   // this will be triggered whenever user will be updated
  //   // console.log('updated user', user);
  //   // if (user) {
  //   //   // connect to backend now
  //   // }
  // }, [valid]);

  function toggleBtnClassUnChecked(eleClassList) {
    eleClassList.replace('st-btn-primary-checked', 'st-btn-primary');
}

  const setDefault = (e) => {
    if (e.currentTarget.value == 'on' && options.find(el => el).length == 3) {
      setOldSelections({});
      setValid(false);
      for (let i = 0; i < options.find(el => el).length; i++) {
        for (let j = 0; j < options.length; j++) {
          toggleBtnClassUnChecked(
            document.getElementById(
              'radio-' + i + '-' + j + '-' + uniqueId).nextSibling.classList);
        }
      }
    }
    if (e.currentTarget.value == 'on') {
      prevRes = response;
      // setOldSelections(prevRes);
      setDefaultValue({ default: '-1' });
      //if(options.length===5){
        
          data.Survey.questions.forEach(x=>{
            console.log(x.id);
            document
          .getElementById('radio-3-' + x.id + '-' + uniqueId)
          .nextSibling.click();
          })
          

      //reloadParent={...reloadParent()};
      //store.istoggleon=false;
      //this.forceUpdate(<ButtonGroupComp/>);
    }
    // else{
    //   console.log(prevRes);
    // }
  };

  const handleSelection = (data) => {
    setOldSelections({ ...oldSelections, ...data });

    if(data !== "Not Tried"){
      setToggle(false)
      // setDefaultValue({ default: '' });
     }
     else{
      setToggle(true)
     }
  
  };

  

  return (
    <div className='st-div-container'>
      {drugs.map((drug, id) => (
        options[id] ?
        <div key={id}>
          <p
            className='st-multiselectlabel'
            key={id}
            id={`drug-${id}-${uniqueId}`}
          >
            {drug}
          </p>

          <ButtonGroupComp
            store={store}
            options={options[id]}
            OnButtonGroupSelection={handleSelection}
            questionId={id}
            uniqueId={uniqueId}
          />
        </div>: null
      ))}
      <br></br>
      <div>
        <label className='st-multiselectnotsure' variant={'st-btn-font'}>
        
          <input
            checked={skip}
            onChange={(e) =>
              setToggle(!skip, console.log(e.currentTarget.value))
            }
            type='checkbox'
            name='skip'
            id={`skip-${uniqueId}`}
            onClick={setDefault}
          />  {' '}
          {data.Survey.lastQuestion.question}
        
        </label>
      </div>
    {valid && <span className='error'> Please choose value from every row</span>}

      <div className='st-div-multiselect-submit'>
        <button
          type='button'
          className={'st-multiselectsubmit'}
          disabled={disable}
          onClick={(event) => {
            event.preventDefault();
            if (!skip) {
              const result = Object.values(oldSelections);
              
              if(options.find(el => el).length===3 ){
              //valid=true;
              result.length!= drugs.length?setValid(true):setValid(false);
                //setDisabled(true);
                console.log(valid);
              }
              else{
                
              for (let i = 0; i < drugs.length; i++) {
                if (options[i] && !oldSelections.hasOwnProperty(i)) {
                  let optionsCount = options[i].length;
                  oldSelections[i] = options[i][optionsCount - 1];
                }
                
              }
            }
              
              response = { ...oldSelections, ...triageCheck};
            
            }
            else {
              response={...defaultValue,...triageCheck};
              if(options.find(el => el).length===3){
              sendPostBack(JSON.stringify(response));
              //setValid(false);
              }
              setDisabled(true);
            }
            const result = Object.values(oldSelections);
           if(!(options.find(el => el).length===3 && result.length!= drugs.length)){
            for (let i = 0; i <= options.length; i++) {
              for(let j=0;j<=options.length;j++){
              let checkDisable=document.getElementById(
                'radio-'+i+'-' + j + '-' + uniqueId);
              var inputVal = "";
              if (checkDisable) {
                inputVal = checkDisable;
                //console.log(inputVal);
                inputVal.disabled=true;
              }

              // var inputVal = "";
              // if (checkDisable) {
              //   inputVal = checkDisable;
              //   console.log(inputVal);
              //   inputVal.nextSibling.classList.replace('st-btn-check','st-disab');
              // }
            }
              }
            
              // for (let i = 0; i <= options.length; i++) {
              let checkDisable=document.getElementById(
                'skip'+'-'+uniqueId);
              var inputVal = "";
              if (checkDisable) {
                inputVal = checkDisable;
                //console.log(inputVal);
                inputVal.disabled=true;
              }
              sendPostBack(JSON.stringify(response));
             setDisabled(true);
            // setValid(false);
          //valid=false;
           }
            console.log(JSON.stringify(response))
            
            
          }}
        
        
        >
          {' '}
          Submit
        </button>
      </div>
    </div>
  );
}
export default MultiSelect;
