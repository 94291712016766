import React, { useMemo, useEffect, useState } from 'react';
import {
  Components,
  createDirectLine,
  createCognitiveServicesSpeechServicesPonyfillFactory,
  createStore,
} from 'botframework-webchat';
import DEFAULT_OPTIONS from '../common/default-style-options';
import axios from 'axios';
import 'antd/dist/antd.css';
import './../../App.scss';
import { Card, Menu, Dropdown } from 'antd';
import sendMessage from 'botframework-webchat-core/lib/actions/sendMessage';
import setSendBox from 'botframework-webchat-core/lib/actions/setSendBox';
import submitSendBox from 'botframework-webchat-core/lib/actions/submitSendBox';
import sendEvent from 'botframework-webchat-core/lib/actions/sendEvent';
import ChatSendBox from './autocomplete';
import { saveAs } from 'file-saver';
import WebChatContainter from './web-chat-container';
import { useHistory } from 'react-router-dom';
import useUnload from '../../hooks/useUnload';
import { sendCustomEvent } from '../../utilities/web-chat.helper';
import CustomActionButtons from './customActionButtons';
import MultiSelect from './MultiSelect';
import { camelCase } from 'lodash';
import MultiSelectCheckbox from './MultiSelectCheckbox';
import DropdownAutocomplete from './DropdownAutocomplete';
import Cookies from 'js-cookie';
import './web-chat.scss';
import Base64 from 'components/landing/Base64';
import colors from './colors';
import CloseChatPopup from './CloseChatPopup';
var md = require('markdown-it')();

const { Composer, BasicWebChat, AdaptiveCardContent, HeroCardContent } =
  Components;

function getRandomInt(max, min = 0) {
  return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
}

const WebChat = ({
  token,
  rootSearchValue,
  updateToken,
  soguid,
  mrnData,
  conversationId,
  requestType,
  showEndChat,
  endChatOnClick,
  mailIntent,
  smartTriageSkillPayload
}) => {
  const clearWindow = () => {

    if (_satellite) {
      console.log("triggered survey event");
      console.log("Time: ", new Date());
      _satellite.kpCustomEvent("trackLinkClick", {
        linkName: "End conversation",
        location: "left rail",
        type: "button"
      });
    }
    setTimeout(() => {
      // window will be closed if opened from another window
      // window.close();
      // open(window.location, '_self').close();
      // // if windows is not closed then just redirect to /valri with clear get parameters
      // window.location.replace('/valri');  
      // clear cookies
      //setSignedOn(false);
      //Cookies.remove('soguid');
    }, 60000);

  }
  const directLine = useMemo(
    () =>
      createDirectLine({
        token,
        conversationId,
        webSocket: true,
        watermark: '-'
      }),
    [token, conversationId, mrnData]
  );
  //const[directLine, setDirectLine] = useState(createDirectLine({ token }));
  const [conversatinoId, setConversationId] = useState(conversationId);
  const [enableUserInput, setEnableUserInput] = useState(false);
  const [buttonselected, setButtonselected] = useState(false);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const history = useHistory();
  const [isSignedOn, setSignedOn] = useState(true);
  const setConversationID = (value) => {
    store.dispatch(sendMessage(rootSearchValue));
    if (value === conversatinoId) console.log('conversation id not changed');
    else setConversationId(value);
    console.log('Conversation ID : ' + value);

    const params = new URLSearchParams();
    if (value) {
      //params.append('conversationId', value);
      //params.append('token', token);
    }
    history.push({ search: params.toString() });
  };
  const [disBot, setDisBot] = useState(false);
  const disableBot= ()=>{
    setDisBot(true)
  }

  const collectEvents = async (values) => {
    console.log('collectEvent : ' + values);
    // if (window.univcbUiConfig) {
    //     const res = await fetch(window.univcbUiConfig.serviceUrl + '/log/insights', {
    //         method: "POST",
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ id: values })
    //     })
    //     const data = await res.json();
    //     return data;
    // }
  };

  const disableActions = (val, flag) => {
    for (let i = 0; i < val.length; i++) {
      val[i].disabled = true;
      val[i].style.pointerEvents = 'none';
      if (!flag)
        val[i].style.opacity = '0.5';
    }
  };


  const storeMiddleware = () => (next) => (action) => {
    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
      console.log(action.type);
      setConversationID(action.payload.directLine.conversationId);
     let cookiesdata = document.cookie;
     //let cookiesdata = "kp-visitor-id=KPNPSf89c1db609e98c84cc1c56c694d9ccbf; LtpaToken2=0.105696358767; KPSessionId=14335b4a181fe2b23d10ac1dff88e7df6314c42132f499cb487; ClientCookie=null; S=1; kpLanguage=en-US; soguid=100157853; TS016b17ea=01d095d8e762ed2cb3400728e69a7902bce9b98335eeaa52f9ba7bdb9100987049ccc11138faec95a6934fa97d696624d10c9eaddcd61409c6c516bb20aefb4d859770f532; dtCookie=v_4_srv_30_sn_E4C0E51087BF59561CBE2D1AD37FA683_perc_100000_ol_0_mul_1_app-3Aea7c4b59f27d43eb_0; ObSSOCookie=H/WFdIT66ax3U1+LgsCfua/jsOmUMSbJAO8wovTx4uKTmPDWbHFtLdbKEsuYj3G8wBVxFZkCgo5KuGsFCxZBZqWFMc3sujqC//wLzIxvNelyo3E78C99IqTrfgsrawCjzi9xxfQHGKbL1iVI6ksrVQDobPCcHX+KIR5qEytKWS43NBs5kwEI21ufJ4skB2O95pKyVbd8TZ7AvOPsDW3/ljNF59CU0Ing1C7eAAF/aYzH+caBFDikzz5IIgeNlKP7Ozrh3zP607ZnWnhQMZVk7S2g14Lc1Wn5FWYZ9WvldwEetUMRLSxkbYQr2ZzB6Wuk0Kxg3ge0SYaYt+lAr2q5Eh1P7pWc4jlO1XodzyEughAqxo9ZT29cKDNYwnoul2n7JjSIp1EivQWfc+4pi5Sh6+nmH42W44os98cSvU2hEQTNAqfxlaNyHtzdREecRzaj9HpkwHTIvQKIzq+ptMSH0QlOdHnjifX0myefC6fSOISWCsXTJjrHzrFRaec2LDsDhQWuVw1gXCqFB7Lsl5rPXp4KojH6gUdSyafAtMsn86OMwZWmWMTxekpoqAzF2LX53wXRaKRBrAidyG5uB57WcA==; jwt-token=eyJhbGciOiJSUzI1NiIsIng1dCI6Ik9fbG8xVkIzMnZITmw5RWlQak04Vng3bVYtYyJ9.ew0KICJpc3MiOiAiLmthaXNlcnBlcm1hbmVudGUub3JnIiwNCiAic3ViIjogIi9zZWN1cmUiLA0KICJuYmYiOiAxNjU0Mjg3MDc1LA0KICJleHAiOiAxNjU0Mjk0Mjc1LA0KICJndWlkIjogIjEwMDE1Nzg1MyIsDQogImtwc2Vzc2lvbmlkIjogIjE0MzM1YjRhMTgxZmUyYjIzZDEwYWMxZGZmODhlN2RmNjMxNGM0MjEzMmY0OTljYjQ4NyINCn0.zOay5sgZe5tqUN8nMloapfyaxdwSupBaFuF26N3tV6RnFyCb7JvtUPGRBntfBiMZoEmDWGDK8rzJwpMo_c86GrOvCAECXDkumbEb_IgBgewNTAg5f6-iwo8IAJSUjuQ0X9KzFd49bGxKS47wQxXKRzzy9jcW7jGODCYyzzNwm74sDKV_Q592NUVaPIhzweH3TDzMFPAILFhPNvjU16BZ4gDhl9pkFiqEpuBIZZ_Y_HmX5ZK_4UsuEeh6FNS_qouDMGuSV2Q7INhKUUeyYPG9CqDDb-XEADE5pRl5kGXn3HHqA9icxnhozKFp2ZXiPsC3rpjOcVW0HK9CWjLi1kChrQ";
      setUserId(action.meta.userID);
      setUserName(action.meta.username);
      sendCustomEvent({
        directLine,
        userId: action.meta.userID,
        userName: action.meta.username,
        name: 'window-load',
        requestType: requestType.toLowerCase() == "appointment" ? 'smarttriageskill' : requestType.toLowerCase(),
        eventPayload: requestType.toLowerCase() == "appointment" ? smartTriageSkillPayload : requestType.toLowerCase() == "memberservice" ? Base64.btoa(cookiesdata) : mrnData
      });

    }
    
    if (action.type == 'DIRECT_LINE/RECONNECT') {
      console.log(action.type);
    }
    if (action.type == 'DIRECT_LINE/DISCONNECT') {
      console.log(action.type);
    }
    if (action.type == 'DIRECT_LINE/DISCONNECT_FULLFILLED') {
      console.log(action.type);
    }
    if (action.type == 'DIRECT_LINE/CONNECT') {
      //setConversationID(action.payload.directLine.conversationId);
    }
    if (action.type == 'DIRECT_LINE/DELETE_ACTIVITY') {
      console.log('Delete chat');
    } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
      if (action.payload.activity.name === 'save-transcript') {
        saveTranscript(action.payload.activity.value);
      }
      if (action.payload.activity.name === 'end-chat') {
        clearWindow();
      }
      if (action.payload.activity.name === 'enable-chat') {
        setEnableUserInput(true);
        if (mailIntent) {
          store.dispatch(setSendBox(mailIntent));
          store.dispatch(submitSendBox());
          mailIntent = null;
        }
      }
      if (action.payload.activity.name === 'disable-chat') {
        setEnableUserInput(false);
      }
      if (action.payload.activity.name == 'check-session-timeout') { // every 5 mins 

        // var remaining_time =  window.opener.$kp.GS.SessionTimeout.remaining // (MM:SS)
        // if the remaining_time < 5 min :            
           // reset window.opener.$kp.GS.SessionTimeout.resetTimeout('resetForMemberServiceChat');
      }
      if (action.payload.activity.name == 'is-signedon') {
        try {
          fetch('/is-signedon', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          })
            .then((response) => {
              if (response.status >= 400 && response.status < 600) {
                throw new Error('Bad response from server');
              }
              return response.json();
            })
            .then((returnedResponse) => {
              if (
                returnedResponse['isSignedOn'] == undefined ||
                returnedResponse['isSignedOn'] == false
              ) {
                clearWindow();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (e) {
          console.log(e);
        }
      }
    }
    if(requestType == "appointment"){
      var targetNode = document.querySelectorAll(".ac-pushButton");
      let count=0;
      for (let i = 0; i < targetNode.length; i++) {
        if (i >= count) {
          targetNode[i].className += " st-checkbox-submit ac-pushbutton-background";        
          count++;
        }
      }
    }
    return next(action);
  };
  const store = useMemo(() => createStore({}, storeMiddleware), [token]);

  const [botPonyfill, setBotPonyfill] = useState();

  useEffect(() => {

    //const script = document.createElement('script');

    //script.src = 'https://assets.adobedtm.com/dca8f73c45d4/3a5f7a2d196e/launch-EN5758b947d317400eb80f957850d31aa5-development.min.js';
    //script.src = 'https://assets.adobedtm.com/launch-ENbd993aa07d4b4467b8a02336a9fe6f2c.min.js';
    //script.async = true;

    //document.body.appendChild(script);

    const headers = {
      'Ocp-Apim-Subscription-Key':
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      'Content-type': 'text/plain; charset=utf-8',
    };
    axios
      .post(
        `https://${process.env.REACT_APP_SUBSCRIPTION_SERVER_REGION}.api.cognitive.microsoft.com/sts/v1.0/issuetoken`,
        {},
        {
          headers: headers,
        }
      )
      .then((res) => {
        return createCognitiveServicesSpeechServicesPonyfillFactory({
          credentials: {
            region: process.env.REACT_APP_SUBSCRIPTION_SERVER_REGION,
            authorizationToken: res.data,
          },
        });
      })
      .then((res) => {
        setBotPonyfill(() => res);
      });
  }, []);

  useUnload(() => {
    sendCustomEvent({ directLine, name: 'window-unload' });
    Cookies.remove('soguid');
    if (requestType == 'memberservice') {
      fetch("https://kpfdoorsoicbdevuws2v5.azurefd.net" + '/disconnectGenesys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch(() => {
        console.log('Error: The service is currently unavailable. Please try again later.');
      });      
    }
  });

  const [resetShow, setResetShow] = useState(false);
  const handleClose = () => {
    setResetShow(true);
  }

  const changeCloseModal = (action) => {
    setResetShow(false);
    if (action === "yes") {
      store.dispatch(setSendBox("End Chat"));
      store.dispatch(submitSendBox());
      // store.dispatch(sendEvent("Reset"));
    }
  }


  let style_options = DEFAULT_OPTIONS;
  style_options['bubbleMaxWidth'] = '100%';

  const onClick = ({ key }) => {
    if (key === 'Clear Chat') {
      store.dispatch(sendEvent('clear-conversation', ''));
      updateToken();
    }
    if (key === 'Feedback') {
      store.dispatch(setSendBox('feedback'));
      store.dispatch(submitSendBox());
    }
    if (key === 'Transcript') {
      store.dispatch(setSendBox('get transcript'));
      store.dispatch(submitSendBox());
    }
  };

  const saveTranscript = (data) => {
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    saveAs(
      blob,
      `transcript ${new Date()
        .toLocaleString()
        .replace(/ /g, '')
        .replace(/\//g, '-')
        .replace(/:/g, '-')}.txt`
    );
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key='Transcript'>
        <a onClick={(e) => e.preventDefault()}>Get transcript</a>
      </Menu.Item>
      {/* <Menu.Item key="Clear Chat">
        <a onClick={e => e.preventDefault()}>
         Clear Chat
        </a>
      </Menu.Item> */}
      <Menu.Item key='Feedback'>
        <a onClick={(e) => e.preventDefault()}>Feedback</a>
      </Menu.Item>
    </Menu>
  );

  const attachmentMiddleware = () => (next) => (card) => {
    const { activities } = store.getState();
    const messageActivities = activities.filter(
      (activity) => activity.type === 'message'
    );
  
    const recentBotMessage = messageActivities.pop() === card.activity;
    if (card.attachment.content !== undefined && card.attachment.content.trigger === 'multiselectmedicine') {
      let response = card.attachment.content.question;
      let uniqueId = card.attachment.content.adaptiveReactCardId;
      return <MultiSelect store={store} res={response} uniqId={uniqueId} />;
    } else if (card.attachment.content !== undefined && card.attachment.content.trigger === 'multiselectcheckbox') {
      let response = card.attachment.content.question;
      let uniqueId = card.attachment.content.multiSelectReactCardId;
      return (
        <MultiSelectCheckbox store={store} res={response} uniqId={uniqueId} />
      );
    }
    else if (card.attachment.content !== undefined && card.attachment.content.trigger === 'dropdownautocomplete') {
      let response = card.attachment.content.question;
      let uniqueId = card.attachment.content.multiSelectReactCardId;
      return (
        <DropdownAutocomplete store={store} res={response} uniqId={uniqueId} />
      );
    }
     else {
      switch (card.attachment.contentType) {
        case 'application/vnd.kp.buttons':
          return (
            <CustomActionButtons
              buttons={card.attachment.content}
              sendText={(text) => {
                store.dispatch(setSendBox(text));
                store.dispatch(submitSendBox());
              }}
            />
          );
        case 'application/vnd.microsoft.card.adaptive':
          return (
            <AdaptiveCardContent
              actionPerformedClassName='card__action--performed'
              content={card.attachment.content}
              disabled={!recentBotMessage}
            />
          );
        case 'application/vnd.microsoft.card.hero':
          return (
            <HeroCardContent
              actionPerformedClassName='card__action--performed'
              content={card.attachment.content}
              disabled={!recentBotMessage}
            />
          );
        default:
          return next(card);
      }
    }
  };

  return (
    <>
      {isSignedOn ? (
        <Composer
          store={store}
          directLine={directLine}
          styleOptions={{ ...style_options, hideSendBox: true }}
          webSpeechPonyfillFactory={botPonyfill}
          attachmentMiddleware={attachmentMiddleware}
          //renderMarkdown={requestType == 'appointment' ? md.render.bind(md) : undefined}   // required only for Appointmet flow 
        >
          <>
            <div className='main-chat-height-container'>
              <div className='main-chat-height'>
                <div className={disBot?'main-chat-height-wrapper chat-inputbox-container-ptr-events':'main-chat-height-wrapper'}>
                  <BasicWebChat />
                </div>
                <div
                  className={`${!enableUserInput ? 'input-box-disabled' : ''}`}
                >
                  {requestType === 'appointment' ? null : <ChatSendBox store={store} emojiattach={buttonselected} userId={userId} directline={directLine} userName={userName} />}

                </div>
                <div>
                  {requestType === 'memberservice' ? <button className='endchat-button' onClick={handleClose}>Close Chat</button> : null}
                </div>
                <div>
                  {resetShow &&
                    <CloseChatPopup show={true} changeShow={changeCloseModal} store={store} />}
                </div>
                {showEndChat ? (
                  <button className='endchat-button' onClick={endChatOnClick}>
                    End Chat
                  </button>
                ) : null}
              </div>
            </div>
          </>
        </Composer>
      ) : (
        <>
          <div>
            <p>
              {' '}
              Your session is timedout. Please Sign-in to use the Smart tool{' '}
            </p>
            <a
              target='_blank'
              href='https://healthy.kaiserpermanente.org/southern-california/sign-on#/signon'
            >
              {' '}
              Click Here to Sign-in
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default WebChat;


